import gql from "graphql-tag";
import { invoiceListingFragment } from "./InvoiceListing";
export const parentInvoiceListingFragment = gql`
  fragment ParentInvoiceListing on Invoice {
    ...InvoiceListing,
    children {
      id      
    }
  }
  ${invoiceListingFragment}
`;