export const PHONE_AREA_MASK = "99";
export const PHONE_EXTENSION_MASK = "99999";
export const PHONES_NAMING_OPTIONS = [{
  value: 'main',
  label: 'Principal'
}, {
  value: 'home',
  label: 'Casa'
}, {
  value: 'work',
  label: 'Trabalho'
}, {
  value: 'other',
  label: 'Outro'
}];