import { OUTSIDE_STATE } from "../constants/invoices/InvoiceFieldsOptions";
import { NOT_TAXABLE, PREVIOUS_TAXABLE_BY_SUBSTITUTION } from "../constants/taxProfile/TaxFieldsOptions";
export const calculatePIS = (pis, form, indexKey) => {
  if (!pis) return;
  if (pis.errors) {
    if (!pis.status) return;
    form.setFieldValue(['items', indexKey, 'pis'], undefined);
    return;
  }
  const values = {
    'taxSituation': pis.taxSituation,
    'basis': pis.basis,
    'rate': pis.rate,
    'quantity': pis.quantity,
    'rateByValue': pis.rateByValue,
    'value': pis.value,
    'taxProfileId': pis.taxProfile?.id,
    'basisReductionPercentage': pis.basisReductionPercentage
  };
  form.setFieldValue(['items', indexKey, 'pis'], values);
};
export const calculateIPI = (ipi, form, indexKey) => {
  if (!ipi) return;
  const values = {
    'ipiLegalFrameworkId': ipi.ipiLegalFrameworkId,
    'taxSituation': ipi.taxSituation,
    'basis': ipi.basis || 0,
    'rate': ipi.rate || 0,
    'quantity': ipi.quantity || 0,
    'rateByValue': ipi.rateByValue || 0,
    'value': ipi.value || 0,
    'taxProfileId': (ipi.taxProfile || {}).id
  };
  form.setFieldValue(['items', indexKey, 'ipi'], values);
};
export const calculateCOFINS = (cofins, form, indexKey) => {
  if (!cofins) return;
  const values = {
    'taxSituation': cofins.taxSituation,
    'basis': cofins.basis,
    'rate': cofins.rate,
    'quantity': cofins.quantity,
    'rateByValue': cofins.rateByValue,
    'value': cofins.value,
    'taxProfileId': cofins.taxProfile?.id,
    'basisReductionPercentage': cofins.basisReductionPercentage
  };
  form.setFieldValue(['items', indexKey, 'cofins'], values);
};
export const calculateICMS = (icms, form, indexKey, operationDestination, taxes = undefined) => {
  if (!icms) return;
  if (icms.errors) {
    form.setFieldValue(['items', indexKey, 'icms'], undefined);
    return;
  }
  const values = {
    'goodsOrigin': icms.goodsOrigin,
    'taxSituation': icms.taxSituation,
    'methodBasis': icms.methodBasis,
    'basisReductionPercentage': icms.basisReductionPercentage,
    'basis': icms.basis,
    'rate': icms.rate,
    'value': icms.value,
    'fcpBasis': icms.fcpBasis,
    'fcpRate': icms.fcpRate,
    'fcpValue': icms.fcpValue,
    'methodBasisSt': icms.methodBasisSt,
    'mvaRate': icms.mvaRate,
    'basisReductionPercentageSt': icms.basisReductionPercentageSt,
    'basisSt': icms.basisSt,
    'rateSt': icms.rateSt,
    'valueSt': icms.valueSt,
    'fcpBasisSt': icms.fcpBasisSt,
    'fcpRateSt': icms.fcpRateSt,
    'fcpValueSt': icms.fcpValueSt,
    'unencumberedRate': icms.unencumberedRate,
    'unencumberedBasisReduction': icms.unencumberedBasisReduction,
    'unencumberedBasis': icms.unencumberedBasis,
    'unencumberedValue': icms.unencumberedValue,
    'unencumberedReason': icms.unencumberedReason,
    'deferralRate': icms.deferralRate,
    'deferralValue': icms.deferralValue,
    'valueWithDeferral': icms.valueWithDeferral,
    'taxProfileId': icms.taxProfile?.id,
    'taxProfileName': icms.taxProfile?.name,
    'cestId': icms?.cestId,
    'taxBenefitCodeId': icms?.taxBenefitCodeId,
    'ncmIds': icms?.ncmIds || []
  };
  form.setFieldValue(['items', indexKey, 'icms'], values);
  if (icms.taxProfile) {
    const cfop = (icms.taxProfile.cfops.filter(cfop => cfop.destination === operationDestination) || [])[0];
    form.setFieldValue(['items', indexKey, 'cfopId'], cfop?.cfopId);
  }
  if ([NOT_TAXABLE, PREVIOUS_TAXABLE_BY_SUBSTITUTION].includes(icms.taxSituation) && form.getFieldValue('operationDestination') === OUTSIDE_STATE) calcICMSTransfer(icms, form, indexKey);
  if (form.getFieldValue(['items', indexKey]).cfopId && taxes) {
    calculateICMSDestination(taxes?.icmsDestination, form, indexKey);
    calculateICMSShared(taxes?.icmsShared?.invoiceItemIcmsSharedCalc, form, indexKey);
  }
};
const calcICMSTransfer = (icms, form, indexKey) => {
  if (!icms) return;
  const values = [{
    'goodsOrigin': icms.goodsOrigin
  }, {
    'taxSituation': icms.taxSituation
  }];
  form.setFieldValue(['items', indexKey, 'icmsTransfer'], values);
};
export const calculateICMSDestination = (icms, form, indexKey) => {
  if (!icms) return;
  const values = {
    'basis': icms.basis || 0,
    'rate': icms.rate || 0,
    'interstateRate': icms.interstateRate || 0,
    'provisionalRate': icms.provisionalRate || 0,
    'value': icms.value || 0,
    'originValue': icms.originValue || 0,
    'fcpBasis': icms.fcpBasis || 0,
    'fcpRate': icms.fcpRate || 0,
    'fcpValue': icms.fcpValue || 0
  };
  form.setFieldValue(['items', indexKey, 'icmsDestination'], values);
};
export const calculateICMSShared = (icms, form, indexKey) => {
  if (!icms) return;
  const values = {
    'stateId': icms.stateId,
    'goodsOrigin': icms.goodsOrigin,
    'taxSituation': icms.taxSituation,
    'methodBasis': icms.methodBasis,
    'basis': icms.basis || 0,
    'value': icms.value || 0,
    'basisReductionPercentage': icms.basisReductionPercentage || 0,
    'rate': icms.rate || 0,
    'methodBasisSt': icms.methodBasisSt || 0,
    'mvaRate': icms.mvaRate || 0,
    'basisReductionPercentageSt': icms.basisReductionPercentageSt || 0,
    'basisSt': icms.basisSt || 0,
    'rateSt': icms.rateSt || 0,
    'valueSt': icms.valueSt || 0
  };
  form.setFieldValue(['items', indexKey, 'icmsShared'], values);
};