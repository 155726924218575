import { precisionType } from 'grifo-components';
import { calculateItemsTotal } from "./InvoiceCalculations";
const getValueByKind = (value, precision, form) => {
  return precisionType(value, precision, form.getFieldValue('precisionType'));
};
export const calculateItemGrossTotal = (value, field, form) => {
  if (!form) return;
  if (!field) return;
  let object = form.getFieldValue(['items', field[0]]);
  const quantity = object.quantity || 0;
  const unitaryValue = object.unitaryValue || 0;
  const total = getValueByKind(quantity * unitaryValue, 2, form);
  form.setFieldValue(['items', field[0], 'grossTotal'], total);
  return calculateItemTotal(value, field, form);
};
export const calculateItemUnitaryValue = (value, field, form, operationIssuancePurpose) => {
  if (!form) return;
  if (!field) return;
  if (operationIssuancePurpose != 'complementary_df_e') {
    const item = form.getFieldValue(['items', field[0]]);
    const quantity = item.quantity || 0;
    const grossTotal = item.grossTotal || 0;
    const unitaryValue = getValueByKind(grossTotal / quantity, 4, form);
    form.setFieldValue(['items', field[0], 'unitaryValue'], unitaryValue);
  }
  return calculateItemTotal(value, field, form);
};
export const calculateItemTotal = (value, field, form) => {
  if (!form) return;
  if (!field) return;
  const item = form.getFieldValue(['items', field[0]]);
  const grossTotal = item.grossTotal || 0;
  const freightValue = item.freightValue || 0;
  const insuranceValue = item.insuranceValue || 0;
  const otherExpenses = item.otherExpenses || 0;
  const discountValue = item.discountValue || 0;
  const total = getValueByKind(grossTotal + freightValue + insuranceValue + otherExpenses - discountValue, 2, form);
  form.setFieldValue(['items', field[0], 'total'], total);
  calculateItemsTotal(form);
  return total;
};